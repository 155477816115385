import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { FilterComponent } from '../FilterComponentClass';
import { DATE_FORMAT_PRIME_NG } from '@constants/date.const';
import { standardFormatDate } from '@shared/utils/common-utils';

@Component({
  selector: 'app-date-filter',
  standalone: true,
  imports: [CalendarModule, FormsModule],
  templateUrl: './date-filter.component.html',
})
export class DateFilterComponent extends FilterComponent<string> {
  DATE_FORMAT_PRIME_NG = DATE_FORMAT_PRIME_NG;

  onFilterDataChange(value: string | Date | null): void {
    this.value = value as string;
    this.filterDataChange.emit(value ? standardFormatDate(new Date(value)) : null);
  }
}
