import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({ template: '' })
export abstract class FilterComponent<V> {
  // to get the filter value
  @Input() value!: V | null;

  @Input() placeholder?: string;

  // to handle apply filter button click
  @Output() filterDataChange = new EventEmitter<V | null>();

  constructor() {
    this.placeholder = 'Select Date';
  }

  // to handle filter value change
  abstract onFilterDataChange(event: V): void;
}
